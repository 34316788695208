/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~animate.css/animate.css";

@font-face {
    font-family: spinnaker;
    src: url('assets/fonts/Spinnaker-Regular.ttf');
}
@font-face {
    font-family: ananda;
    src: url('assets/fonts/Ananda-Black-Personal.ttf');
}
@font-face {
    font-family: robotoregular;
    src: url('assets/fonts/roboto/Roboto-Regular.ttf');
}
/* * {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: normal;
  } */

body {
    margin: 0;
    padding: 0;
    background-color: #DAE5E6;
    font-family: 'robotoregular';
}


ngb-carousel .carousel-item .sr-only {
    display: none !important;
}

ngb-carousel .carousel-control-prev .sr-only {
    display: none !important;
}

ngb-carousel .carousel-control-next .sr-only {
    display: none !important;
}

ngb-carousel {
    border: none !important;
}
